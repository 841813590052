import React from "react";
import classes from './Description.module.scss'
import triangle from './img/triangle.svg'
import construction from './img/construction.png'

const Description = (props) => {
    return(
        <div className={classes.description}>
            <div className={classes.left}></div>
            <div className={classes.center}>
                <div className={classes.triangleHolder}><img src={triangle} alt=""/></div>
               <div className={classes.text}>{props.descText}</div>
                <img className={classes.construction} src={construction} alt=""/>
            </div>
            <div className={classes.right}></div>
        </div>
    )
}

export default Description