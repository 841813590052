import React from "react";
import classes from './Lead.module.css'

const Lead = () => {
    return(
        <div className={classes.lead}>
            Профессиональное объединение<br/>экспертов – практиков и специалистов<br/>области государственных<br/>и коммерческих закупок.
        </div>
    )
}

export default Lead;