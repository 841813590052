import React from "react";
import Container from "../LayoutComponents/Container/Container";
import classes from './Footer.module.scss'
import cx from 'classnames'
import logoB from './img/logo_white_216x234_.png'

import iconPost from './img/icons/post.svg'
import iconTel from './img/icons/tel.svg'
import iconTeleg from './img/icons/teleg.svg'
import iconVk from './img/icons/vk.svg'



const Footer = () => {
    return (
        <div className={classes.wrapper}>



            <Container>
                <div className={classes.content}>


                    <div className={classes.phone}>

                        {/*<div className={classes.phoneHolder}> <img src={iconTel} alt=""/></div>8-989-993-37-23*/}
                        <a className={classes.footerLink} href="tel:+79899933723">
                            <div className={classes.phoneHolder}><img src={iconTel} alt=""/></div>
                            8-989-993-37-23</a>


                    </div>
                    {/*<a className={classes.phoneHolder} href="tel:+78142332211">+7(814)-233-22-11</a>*/}

                    <div className={classes.logotypeHolder}><img src={logoB} alt=""/></div>


                    <div className={cx(classes.block02, classes.text)}>
                        <div className={classes.contact}>
                            <div className={classes.iconHolder}><img src={iconPost} alt="Электронная почта"/></div>
                            <a className={classes.regularLink} href="mailto:post@atomicsynergy-goszakaz.ru">post@atomicsynergy-goszakaz.ru</a></div>
                        <div className={classes.contact}>
                            <div className={classes.iconHolder}><img src={iconTeleg} alt="Telegram"/></div>
                            <a className={classes.regularLink} href="https://t.me/atomicsynergygoszaka">t.me/atomicsynergygoszaka</a></div>
                        <div className={classes.contact}>
                            <div className={classes.iconHolder}><img src={iconVk} alt="VK"/></div>
                            <a className={classes.regularLink} href="https://vk.com/public216370340" target="_blank">vk.com/public216370340</a></div>
                    </div>


                </div>
            </Container>
        </div>
    )
}
export default Footer