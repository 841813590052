import React from "react";
import classes from './ColumnInCircle.module.css'

const ColumnInCircle = () => {
    return(
        <div className={classes.circle_holder}>
            <div className={classes.circle}></div>
            <div  className={classes.circle_bg}></div>
        </div>
    )
}

export default ColumnInCircle;