import React from "react";
import btmLine from './img/bottomLine.jpg'

import classes from './Card.module.scss'

const Card = (props) =>{
    return(
        <div className={classes.wrapper}>
            <div className={classes.cardBody}>





                <div className={classes.cardTop}>
                <div className={classes.portraitHolder}>
                    <img src={props.portrait} alt=""/>
                </div>
                 <div className={classes.name}>{props.name.replace(/ /g, '\n')}</div>
                </div>




            <ul  className={classes.ul}>
                {props.activity.map((item, i)=>(
                    <li  key={i} className={classes.li}>{item}</li>
                ))}
            </ul>

            </div>
            <div className={classes.bottomLine}>
                <img style={{width:'100%'}} src={btmLine} alt=""/>
            </div>
        </div>

    )
}
export default Card

