import React from "react";
import classes from './Hero.module.css'
import Title from "../Title/Title";
import LogoWhite from "../LoogoWhite/LogoWhite";
import Lead from "../Lead/Lead";



const Hero = () => {
    return(
        <div className={classes.hero_box}>
            <Title />
            <LogoWhite />
            <Lead />



        </div>
    )

}

export default Hero;