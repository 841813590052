import React from "react";
import classes from './Button.module.scss'


const Button = (props) => {
    return(
        <div>
            <a className={classes.button} href="https://forms.yandex.ru/cloud/634f51c384227c0ee92285f8/">Присоединиться</a>
            {/*<button>Присоединиться</button>*/}
        </div>
                  
        
     )
}

export default Button