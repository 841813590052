import React from "react";
import classes from './JoinUsButtonArea.module.scss'
import Button from "../Button/Button";
// import FeedbackForm from '../FeedbackForm/FeedbackForm'

const JoinUsButtonArea = (props) => {
    return(
        <>
        <div className={classes.joinUsArea}>
            <Button/>
            {/*<FeedbackForm />*/}
        </div>


        </>
    )
}

export default JoinUsButtonArea