import React from "react";
import classes from './Answer.module.scss'
import Container from '../LayoutComponents/Container/Container'
import Description from '../Description/Description'


const Answer = (props) => {
    console.log(props)
    return (
        <Container>

            {

                props.answers.map((item, i) =>

                    <div>
                        <div key={i} className={classes.AnswerItem}>
                            <div className={classes.leftSide}>
                                <div className={classes.imgHolder}>
                                    <img src={item.icon} alt=""/>
                                </div>
                            </div>
                            <div className={classes.center}>{item.text}</div>
                            <div className={classes.rightSide}></div>
                        </div>

                        {item.description ?  <Description descText={item.description} /> : null}



                    </div>
                )
            }

        </Container>
    )
}
export default Answer