import React from "react";
import classes from './GodInCircle.module.css'
import GlowLogo from "../GlowLogo/GlowLogo";
import God from "../God/Gog";



const GodInCircle = () => {
    return(
        <div className={classes.circleHolder}>
        <div className={classes.circle}>
            <GlowLogo />
            <God />

        </div>
        </div>
    )
}

export default GodInCircle;