import React from "react";
import classes from "./God.module.css";
import GodImg from './img/god.svg'
import GodImg2 from './img/God_002.svg'


const God = () => {
    return (
        <>
            <img className={classes.god} src={GodImg} alt=""/>
            <img className={classes.god_500_px} src={GodImg2} alt=""/>
        </>
    )
}
export default God;