import React from "react";
import Slider from 'react-slick'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {dataExperts} from './data'
import {settings} from "./settings";
import Container from "../LayoutComponents/Container/Container";
import Card from "../Card/Card";
import classes from './ExpertsInAreas.module.scss'



const ExpertsInAreas = () => {
    return (
        <div className={classes.wrapper}>

            <div className={classes.title}>ЭКСПЕРТЫ АТОМНОЙ СИНЕРГИИ ПО&nbsp;НАПРАВЛЕНИЯМ</div>
            <Container>
                <Slider {...settings}>
                    {dataExperts.map((item, i) => (
                        <div key={i} style={{width: '100%', boxSizing:'border-box', overflow:'hidden'}}>
                            <Card name={item.name} portrait={item.portrait} activity={item.activity}/>
                        </div>
                    ))}
                </Slider>
            </Container>
        </div>
    )
}
export default ExpertsInAreas