import React from "react";
import classes from './Title.module.css'

const Title = () => {
    return (
        <div className={classes.title_group_desktop}>
            <div className={classes.title_h1}>Атомная <span className={classes.visible_w_640px}><br/></span> синергия</div>
            <div className={classes.title_h2}><i>опытных экспертов <span className={classes.visible_w_640px}><br/></span> и специалистов<br/>в области госзаказа</i></div>
        </div>
    )
}
export default Title;