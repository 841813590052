import React from "react";
import cx from 'classnames'
import Container from "../LayoutComponents/Container/Container";
import classes from './Experts.module.scss'

import icon1 from './img/hands.png'
import icon2 from './img/discussion.png'
import icon3 from './img/team.png'
import icon4 from './img/care.png'
import icon5 from './img/employee.png'
import icon6 from './img/profits.png'
import icon7 from './img/eye.png'
import icon8 from './img/talking.png'


const Experts = (props) => {
    return (
        <div className={classes.experts}>
            <Container>
                <div className={classes.row}>
                    {/*===================1=======================*/}
                    <div className={classes.column}>
                        <div className={classes.item}>
                            <div className={classes.icon}>
                                <img src={icon1} alt=""/>
                            </div>
                            <div className={classes.expertTextContainer}>
                                <ul className={classes.expertText}>
                                    <li>Вступление</li>
                                    <li>в «Гильдию Экспертов</li>
                                    <li>по тендерам и смежным</li>
                                    <li>направлениям»,</li>
                                    <li>объединяющую</li>
                                    <li>топовых</li>
                                    <li>экспертов-практиков</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    {/*======================2====================*/}
                    <div className={classes.column}>
                        <div className={classes.item}>
                            <div className={classes.icon}>
                                <img src={icon2} alt=""/>
                            </div>
                            <div className={classes.expertTextContainer}>
                                <ul className={classes.expertText}>
                                    <li>Проработка</li>
                                    <li>узконаправленных</li>
                                    <li>тем в формате дискуссий</li>
                                    <li>для специалистов</li>
                                    <li>по тендерам</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    {/*======================3====================*/}
                    <div className={classes.column}>
                        <div className={classes.item}>
                            <div className={classes.icon}>
                                <img src={icon3} alt=""/>
                            </div>
                            <div className={classes.expertTextContainer}>
                                <ul className={classes.expertText} title="чек-листы, обучающие материалы услуги и т. д.">
                                    <li>Разработка</li>
                                    <li>совместных проектов,</li>
                                    <li>продуктов, с целью</li>
                                    <li>продвижения и продажи</li>
                                    <li>заинтересованным</li>
                                    <li>участникам рынка</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    {/*=====================4=====================*/}
                    <div className={classes.column}>
                        <div className={classes.item}>
                            <div className={classes.icon}>
                                <img src={icon4} alt=""/>
                            </div>
                            <div className={classes.expertTextContainer}>
                                <ul className={classes.expertText}>
                                    <li>Маркетинговая</li>
                                    <li>и пиар поддержка</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    {/*=====================5=====================*/}
                    <div className={classes.column}>
                        <div className={classes.item}>
                            <div className={classes.icon}>
                                <img src={icon5} alt=""/>
                            </div>
                            <div className={classes.expertTextContainer}>
                                <ul className={classes.expertText}>
                                    <li>Спикерство</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    {/*=====================6=====================*/}
                    <div className={classes.column}>
                        <div className={classes.item}>
                            <div className={classes.icon}>
                                <img src={icon6} alt=""/>
                            </div>
                            <div className={classes.expertTextContainer}>
                                <ul className={classes.expertText}>
                                    <li>Возможность</li>
                                    <li>дополнительного</li>
                                    <li>заработка</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    {/*====================7======================*/}
                    <div className={classes.column}>
                        <div className={classes.item}>
                            <div className={classes.icon}>
                                <img src={icon7} alt=""/>
                            </div>
                            <div className={classes.expertTextContainer}>
                                <ul className={classes.expertText}>
                                    <li>Повышение</li>
                                    <li>экспертной</li>
                                    <li>узнаваемости</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    {/*=====================8=====================*/}
                    <div className={classes.column}>
                        <div className={classes.item}>
                            <div className={classes.icon}>
                                <img src={icon8} alt=""/>
                            </div>
                            <div className={classes.expertTextContainer}>
                                <ul className={classes.expertText}>
                                    <li>Условия участия:</li>
                                    <li>обсуждается</li>
                                    <li>в индивидуальном</li>
                                    <li>порядке</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    {/*==========================================*/}
                </div>
            </Container>
        </div>
    )
}

export default Experts