import React from "react";
import Logo from './img/logo_white_216x234.png'
import classes from './LogoWhite.module.css'
const LogoWhite = () => {
    return(
        <div>
            <img className={classes.logo_white} src={Logo} alt="logo"/>
        </div>
    )
}
export default LogoWhite;