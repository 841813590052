import React from "react";


import Header from "./components/Header/Header";
import Pain from "./components/Pain/Pain";
import Answer from './components/Answer/Answer'
import VideoPlayer from "./components/VideoPlayer/VideoPlayer";
import JoinUsButtonArea from "./components/JoinUsButtonArea/JoinUsButtonArea";
import Experts from "./components/Experts/Experts";
import Founder from "./components/Founder/Founder";
import VideoAnnouncement from "./components/VideoAnnouncement/VideoAnnouncement";
import ExpertsInAreas from "./components/ExpertsInAreas/ExpertsInAreas";
import Footer from "./components/Footer/Footer";
import ContactForm from './components/ContactForm/ContactForm'
import space01 from './components/VideoAnnouncement/img/top_line_.svg'
import space02 from './components/JoinUsButtonArea/img/spaser.jpg'
import space03 from './components/Founder/img/top_line.jpg'
import space04 from './components/Footer/img/topLine.jpg'

import './App.scss';

let painData = {
    pain01: {
        number: '01',
        h1:
            'Не хватает опыта, практики и\u00A0компетенций?'
        ,
        h2: 'При решении профессиональных задач сталкиваетесь\nс\u00A0недостатком опыта, практики и\u00A0компетенций?'
    },
    pain02: {
        number: '02',
        h1: 'Сталкиваетесь с\u00A0недостатком нормативно правовой поддержки',
        h2: 'от\u00A0экспертов смежных направлений?'
    },
    pain03: {
        number: '03',
        h1: 'Не\u00A0хватает профессиональных коммуникаций',
        h2: 'и\u00A0обмена опытом среди компетентных коллег?'
    }
}




const answers = {

    answers01:[
        {
            "index": "01",
            "icon": "http://www.xn--n1ah6a.online/icons/answers/icon001.png",
            "text": "Атомная синергия\u00A0– это\u00A0регулярно обновляемая и\u00A0оцифрованная база знаний в\u00A0области госзакупок."
        },
        {
            "index": "02",
            "icon": "http://www.xn--n1ah6a.online/icons/answers/icon002.png",
            "text": "Нормативно\u00A0– правовая законодательная база с\u00A0комментариями экспертов-практиков."
        },
        {
            "index": "03",
            "icon": "http://www.xn--n1ah6a.online/icons/answers/icon003.png",
            "text": "Аккумуляция лучших практик и\u00A0решений в\u00A0области госзакупок."
        },
        {
            "index": "04",
            "icon": "http://www.xn--n1ah6a.online/icons/answers/icon004.png",
            "text": "Тщательно отобранные и\u00A0проверенные на\u00A0практике шаблоны документов (закупочная документация, дорожные карты, чек-листы и\u00A0многое другое)."
        },
        {
            "index": "05",
            "icon": "http://www.xn--n1ah6a.online/icons/answers/icon005.png",
            "text": "Участие в\u00A0мероприятиях, повышающих квалификацию участников рынка госзаказа."
        },
    ],
    answers02:[
        {
            "index": "06",
            "icon": "http://www.xn--n1ah6a.online/icons/answers/icon006.png",
            "text": "Атомная синергия аккумулирует в\u00A0себе опыт лучших практик из\u00A0смежных направлений."
        },
        {
            "index": "07",
            "icon": "http://www.xn--n1ah6a.online/icons/answers/icon007.png",
            "text": "Эта потребность возникает у\u00A0каждого, кто\u00A0работает в\u00A0системе госзакупок, так как для\u00A0качественного выполнения работ необходима информационная поддержка смежников.",
            "description": "Например: при\u00A0выполнении госзаказов в\u00A0строительстве\u00A0– начиная от\u00A0проектирования до\u00A0комплексного строительства, возникает потребность частого взаимодействия со\u00A0метчиками, инженерами, юристами в\u00A0области градостроительства и\u00A0гражданско-правового законодательства. "
        },
        {
            "index": "08",
            "icon": "http://www.xn--n1ah6a.online/icons/answers/icon008.png",
            "text": "Аккумуляция лучших практик и\u00A0решений в\u00A0области госзакупок."
        },
        {
            "index": "09",
            "icon": "http://www.xn--n1ah6a.online/icons/answers/icon009.png",
            "text": "Атомная синергия объединяет опытных практиков смежных отраслей, в\u00A0том числе юристов по\u00A0узким специализациям в\u00A0различных направлениях."
        },
        {
            "index": "10",
            "icon": "http://www.xn--n1ah6a.online/icons/answers/icon010.png",
            "text": "Здесь вы\u00A0найдете лучшие практики экспертов смежных направлений. "
        },
    ],
    answers03:[
        {
            "index": "11",
            "icon": "http://www.xn--n1ah6a.online/icons/answers/icon011.png",
            "text": "Закрытые чаты."
        },
        {
            "index": "12",
            "icon": "http://www.xn--n1ah6a.online/icons/answers/icon012.png",
            "text": "Дискуссионные площадки."
        },
        {
            "index": "13",
            "icon": "http://www.xn--n1ah6a.online/icons/answers/icon013.png",
            "text": "Мастер–классы."
        },
        {
            "index": "14",
            "icon": "http://www.xn--n1ah6a.online/icons/answers/icon014.png",
            "text": "Иные профильные мероприятия в\u00A0онлайн и\u00A0офлайн форматах."
        },
        {
            "index": "15",
            "icon": "http://www.xn--n1ah6a.online/icons/answers/icon015.png",
            "text": "Профессиональное общение, обмен опытом, повышение квалификации участников рынка госзакупок."
        },
    ]
}





const videos = {
    anons:{
        "name": "anons",
        "mp4":"http://www.xn--n1ah6a.online/icons/video/anons.mp4",
        "ovg":"http://www.xn--n1ah6a.online/icons/video/anons.ovg",
        "prev":"http://www.xn--n1ah6a.online/icons/video/prev_anons.png"
    },
    video01:{
        "name": "video01",
        "mp4":"http://www.xn--n1ah6a.online/icons/video/video01.mp4",
        "ovg":"http://www.xn--n1ah6a.online/icons/video/video01.ovg",
        "prev":"http://www.xn--n1ah6a.online/icons/video/prev_01.jpg"
    },
    video02:{
        "name": "video02",
        "mp4":"http://www.xn--n1ah6a.online/icons/video/video02.mp4",
        "ovg":"http://www.xn--n1ah6a.online/icons/video/video02.ovg",
        "prev":"http://www.xn--n1ah6a.online/icons/video/prev_02.jpg"
    },
    video03:{
        "name": "video03",
        "mp4":"http://www.xn--n1ah6a.online/icons/video/video03.mp4",
        "ovg":"http://www.xn--n1ah6a.online/icons/video/video03.ovg",
        "prev":"http://www.xn--n1ah6a.online/icons/video/prev_03.jpg"
    },

}



function App() {
    return (
        <div className="App">
            <header>
                <Header/>
            </header>


            <Pain number={painData.pain01.number} h1={painData.pain01.h1} h2={painData.pain01.h2}/>
            <Answer answers={answers.answers01} />
            <VideoPlayer videoPrev = {videos.video01.prev} videoPartMp4 = {videos.video01.mp4} videoPartOgv = {videos.video01.ovg}/>
            <JoinUsButtonArea />

            <div>
                <img className='patchSpacer' src={space02} alt=""/>
            </div>

            <Pain number={painData.pain02.number} h1={painData.pain02.h1} h2={painData.pain02.h2}/>
            <Answer answers={answers.answers02} />
            <VideoPlayer videoPrev = {videos.video02.prev} videoPartMp4 = {videos.video02.mp4} videoPartOgv = {videos.video02.ovg}/>
            <JoinUsButtonArea />

            <div>
                <img className='patchSpacer' src={space02} alt=""/>
            </div>

            <Pain number={painData.pain03.number} h1={painData.pain03.h1} h2={painData.pain03.h2}/>
            <Answer answers={answers.answers03} />
            <VideoPlayer videoPrev = {videos.video03.prev} videoPartMp4 = {videos.video03.mp4} videoPartOgv = {videos.video03.ovg}/>



            <JoinUsButtonArea />
            {/*<ContactForm />*/}

            {/*костыль*/}
            <div>
            <div> <img className='patchSpacer' src={space02} alt=""/></div>
            </div>
            {/*костыль*/}
            <Experts />


            {/*костыль*/}
            <div className='patchSpacerHolder'><img className='patchSpacer' src={space03} alt=""/></div>
            {/*костыль*/}










            <Founder />

            {/*костыль*/}
            <div><img className='patchSpacer'  src={space01} alt=""/></div>
            {/*костыль*/}

            <VideoAnnouncement />
            <ExpertsInAreas />

            {/*костыль*/}
            <div><img className='patchSpacer' src={space04} alt=""/></div>
            {/*костыль*/}

            <Footer />


        </div>
    );
}


export default App;