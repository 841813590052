import React from "react";
import Container from "../LayoutComponents/Container/Container";
import classes from './Founder.module.scss'
import portrait from './img/portrait.jpg'
import topLine from './img/top_line.svg'
const Founder = () => {
    return(
        <div className={classes.founder}>
            {/*<img src={topLine} alt=""/>*/}

            <Container>

            <div className={classes.header}>
                ОСНОВАТЕЛЬ<br/>
                бизнес-платформы<br/>
                «АТОМНАЯ СИНЕРГИЯ»
            </div>
            <div className={classes.main}>

                <div >
                    <img  className={classes.portrait} src={portrait} alt="Александра"/>
                </div>
                <div className={classes.text}>
                    <h3 className={classes.h3}>Александра Астанкова</h3>
<ul className={classes.uList}>
    <li className={classes.listItem}>Эксперт&nbsp;— практик в&nbsp;области государственных и&nbsp;коммерческих закупок с&nbsp;опытом работ более 7&nbsp;лет.</li>
    <li className={classes.listItem}>Руководитель Удалённого тендерного отдела www.astankova.ru.</li>
    <li className={classes.listItem}>Основатель бизнес&nbsp;— платформы «Атомная синергия опытных экспертов и&nbsp;специалистов в&nbsp;области госзаказа и&nbsp;смежных направлений».</li>
    <li className={classes.listItem}>Спикер, автор тематических статей в&nbsp;СМИ.</li>
</ul>
               </div>
            </div>

            </Container>



        </div>
    )
}
export default Founder