export const dataExperts =  [
    {
        "id": "1",
        "name": "Александра Астанкова",
        "portrait": "http://www.xn--n1ah6a.online/icons/portraits/astankova.jpg",
        "activity": [
           ["Эксперт\u00A0— практик в\u00A0области государственных и\u00A0коммерческих закупок с\u00A0опытом работы  более 7\u00A0лет."],
           ["Основатель бизнес\u00A0— платформы «Атомная синергия опытных экспертов и\u00A0специалистов в\u00A0области госзаказа и\u00A0смежных направлений»."],
           ["Руководитель Удалённого тендерного отдела."],
        ]
    },
    {
        "id": "2",
        "name": "Светлана Сол",
        "portrait": "http://www.xn--n1ah6a.online/icons/portraits/sologub.jpg",
        "activity": [
            ["Бизнес-консультант, практик. 28-летний опыт в\u00A0развитии, масштабировании бизнеса, маркетинге и\u00A0менеджменте."],
            ["Приглашённый преподаватель Президентской программы повышения квалификации, специализация: менеджмент и\u00A0маркетинг."],
            
        ]
    },
    {
        "id": "3",
        "name": "Анна Запрудина",
        "portrait": "http://www.xn--n1ah6a.online/icons/portraits/zaprudina.jpg",
        "activity": [
            ["Генеральный директор и\u00A0главный юридический эксперт ООО\u00A0«ЦКУ»"],
            ["13\u00A0лет практического опыта в\u00A0сфере гособоронзаказа."],
        ]
    },
    {
        "id": "4",
        "name": "Екатерина Амосова",
        "portrait": "http://www.xn--n1ah6a.online/icons/portraits/amosova.jpg",
        "activity": [
            ["Практик, бизнес-юрист с\u00A016-летним практическим опытом, обширная судебная практика по\u00A0вопросам банкротства и\u00A0ведения бизнеса."],
            ["Основатель ЮК\u00A0«АМОСОВА И\u00A0ПАРТНЕРЫ» по\u00A0сопровождению бизнеса в\u00A0сфере строительства и\u00A0юрпроекта «ЗАЩИТНИК ПРАВА»."],
        ]
    },
]