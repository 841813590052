import React from "react";
import Container from '../LayoutComponents/Container/Container'

import cx from 'classnames'
import classes from './Pain.module.scss'

import leftBrush from './img/brush_left.svg'
import rightBrush from './img/brush_right.svg'

const Pain = (props) => {
    return (
        <div className={classes.pain}>
            <div className={classes.mobileNumber}>
               <div className={cx(classes.mobileNumberInnerPart, classes.mobileNumberInnerPart__left)}></div>
                <div className={cx(classes.mobileNumberInnerPart, classes.mobileNumberInnerPart__center)}>{props.number}</div>
                <div className={cx(classes.mobileNumberInnerPart, classes.mobileNumberInnerPart__right)}></div>
            </div>




            <div className={classes.leftSideBlock}>
                <div className={classes.numberContainer}>
                    <div className={classes.number}>
                        {props.number}
                    </div>
                </div>
                {/*Joining multiple CSS Modules classes*/}
                <div className={cx(classes.brushContainer, classes.brushContainerLeft)}></div>
            </div>

            <Container>

                <div className={classes.centerBlock}>
                    <div className={classes.h1}>{props.h1}</div>
                    <div className={classes.h2}>{props.h2}</div>
                </div>
            </Container>

            <div className={classes.rightSideBlock}>
                <div className={cx(classes.brushContainer, classes.brushContainerRight)}></div>
            </div>
        </div>
    )
}

export default Pain;