import React from "react";
import ColumnInCircle from "./ColumnInCircle/ColumnInCircle";
import GodInCircle from "./GodInCircle/GodInCircle";
import Hero from "./Hero/Hero"
import classes from "./Header.module.css"

const Header = () =>{
    return(
        <div className={classes.background_gradient}>
            <ColumnInCircle />
            <GodInCircle />
            <Hero />
        </div>
    )
}

export default Header;