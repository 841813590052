import React from "react";
import classes from './VideoPlayer.module.scss'
import {DefaultPlayer as Video} from 'react-html5video'

import '../../styles/styles.css'
import htum from './img/anons_prev.png'
const VideoPlayer = (props) => {
    return(

        <div className={classes.wrapper}>
        <div className={classes.roundScreen}>
            <Video auto  poster={props.prev}>
<source src={props.videoPartMp4} type="video/mp4"/>
<source src={props.videoPartOgv} type="video/ogg"/>
            </Video>
        </div>
    </div>

    )
}

export default VideoPlayer