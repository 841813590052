import React from "react";
import classes from './VideoAnnouncement.module.scss'

import VideoPlayer from "../VideoPlayer/VideoPlayer";


const videos = {
    anons:{
        "name": "anons",
        "mp4":"http://www.xn--n1ah6a.online/icons/video/anons.mp4",
        "ovg":"http://www.xn--n1ah6a.online/icons/video/anons.ovg",
        "prev":"http://www.xn--n1ah6a.online/icons/video/prev_anons.png"
    }}

const VideoAnnouncement = () => {
    return(
        <div className={classes.wrapper}>


                <div className={classes.AnonsHeader}>Видео – анонс</div>


            <VideoPlayer videoPrev = {videos.anons.prev} videoPartMp4 = {videos.anons.mp4} videoPartOgv = {videos.anons.ovg}/>

<div className={classes.patch}></div>
        </div>
    )
}

export default VideoAnnouncement