import React from "react";
import classes from './GlowLogo.module.css'
import GlowLogoImg from './img/glow_logo1.png'

const GlowLogo = () => {
    return(
        <>
            <img className={classes.logo} src={GlowLogoImg} alt=""/>
        </>
    )
}
export default GlowLogo;